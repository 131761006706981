@font-face {
  font-family: 'navIcon';
  src:  url('fonts/navIcon.eot?7r0iyy');
  src:  url('fonts/navIcon.eot?7r0iyy#iefix') format('embedded-opentype'),
    url('fonts/navIcon.ttf?7r0iyy') format('truetype'),
    url('fonts/navIcon.woff?7r0iyy') format('woff'),
    url('fonts/navIcon.svg?7r0iyy#navIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="nav-icon-"], [class*=" nav-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'navIcon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-icon-attachment:before {
  content: "\e988";
  color: #61636b;
}
.nav-icon-file:before {
  content: "\e987";
  color: #797c86;
}
.nav-icon-whatsapp-outline:before {
  content: "\e900";
}
.nav-icon-whatsapp:before {
  content: "\e901";
}
.nav-icon-warning-outline:before {
  content: "\e902";
}
.nav-icon-warning:before {
  content: "\e903";
}
.nav-icon-view-on-outline:before {
  content: "\e904";
}
.nav-icon-view-on:before {
  content: "\e905";
}
.nav-icon-view-off-outline:before {
  content: "\e906";
}
.nav-icon-view-off:before {
  content: "\e907";
}
.nav-icon-video-outline:before {
  content: "\e908";
}
.nav-icon-video:before {
  content: "\e909";
}
.nav-icon-user-square-outline:before {
  content: "\e90a";
}
.nav-icon-user-square:before {
  content: "\e90b";
}
.nav-icon-user-outline:before {
  content: "\e90c";
}
.nav-icon-user-id-outline:before {
  content: "\e90d";
}
.nav-icon-user-id:before {
  content: "\e90e";
}
.nav-icon-user:before {
  content: "\e90f";
}
.nav-icon-tweezer-outline:before {
  content: "\e910";
}
.nav-icon-tweezer:before {
  content: "\e911";
}
.nav-icon-text-ballon-outline:before {
  content: "\e912";
}
.nav-icon-text-ballon:before {
  content: "\e913";
}
.nav-icon-terms-outline:before {
  content: "\e914";
}
.nav-icon-terms:before {
  content: "\e915";
}
.nav-icon-telemedicine-outline:before {
  content: "\e916";
}
.nav-icon-telemedicine-2:before {
  content: "\e917";
}
.nav-icon-telemedicine:before {
  content: "\e918";
}
.nav-icon-team:before {
  content: "\e919";
}
.nav-icon-switch:before {
  content: "\e91a";
}
.nav-icon-square-outline:before {
  content: "\e91b";
}
.nav-icon-square-check-outline:before {
  content: "\e91c";
}
.nav-icon-square-check:before {
  content: "\e91d";
}
.nav-icon-square:before {
  content: "\e91e";
}
.nav-icon-settings:before {
  content: "\e91f";
}
.nav-icon-search-outline:before {
  content: "\e920";
}
.nav-icon-search:before {
  content: "\e921";
}
.nav-icon-round-outline:before {
  content: "\e922";
}
.nav-icon-round-close-outline:before {
  content: "\e923";
}
.nav-icon-round-close:before {
  content: "\e924";
}
.nav-icon-round-check-outline:before {
  content: "\e925";
}
.nav-icon-round-check:before {
  content: "\e926";
}
.nav-icon-round:before {
  content: "\e927";
}
.nav-icon-register-outline:before {
  content: "\e928";
}
.nav-icon-register:before {
  content: "\e929";
}
.nav-icon-refresh-outline:before {
  content: "\e92a";
}
.nav-icon-refresh:before {
  content: "\e92b";
}
.nav-icon-radiology-outline:before {
  content: "\e92c";
}
.nav-icon-radiology:before {
  content: "\e92d";
}
.nav-icon-radio-button-on:before {
  content: "\e92e";
}
.nav-icon-radio-button-off:before {
  content: "\e92f";
}
.nav-icon-quit-outline:before {
  content: "\e930";
}
.nav-icon-quit:before {
  content: "\e931";
}
.nav-icon-question-circle-outline:before {
  content: "\e932";
}
.nav-icon-question-circle:before {
  content: "\e933";
}
.nav-icon-pin-outline:before {
  content: "\e934";
}
.nav-icon-pin:before {
  content: "\e935";
}
.nav-icon-phone-outline:before {
  content: "\e936";
}
.nav-icon-phone:before {
  content: "\e937";
}
.nav-icon-partner-outline:before {
  content: "\e938";
}
.nav-icon-partner:before {
  content: "\e939";
}
.nav-icon-options-outline:before {
  content: "\e93a";
}
.nav-icon-options:before {
  content: "\e93b";
}
.nav-icon-open_in_new:before {
  content: "\e93c";
}
.nav-icon-notification-outline:before {
  content: "\e93d";
}
.nav-icon-notification:before {
  content: "\e93e";
}
.nav-icon-menu-hide:before {
  content: "\e93f";
}
.nav-icon-menu-expand:before {
  content: "\e940";
}
.nav-icon-medical-record-light:before {
  content: "\e941";
}
.nav-icon-medical-record-filled:before {
  content: "\e942";
}
.nav-icon-medical-record:before {
  content: "\e943";
}
.nav-icon-male-outline:before {
  content: "\e944";
}
.nav-icon-male:before {
  content: "\e945";
}
.nav-icon-mail-outline:before {
  content: "\e946";
}
.nav-icon-mail:before {
  content: "\e947";
}
.nav-icon-lock-outline:before {
  content: "\e948";
}
.nav-icon-lock:before {
  content: "\e949";
}
.nav-icon-livia-bubble:before {
  content: "\e94a";
  color: #194ce6;
}
.nav-icon-light-shine:before {
  content: "\e94b";
}
.nav-icon-light:before {
  content: "\e94c";
}
.nav-icon-lab-outline:before {
  content: "\e94d";
}
.nav-icon-lab:before {
  content: "\e94e";
}
.nav-icon-home-outline:before {
  content: "\e94f";
}
.nav-icon-home-2:before {
  content: "\e950";
}
.nav-icon-home:before {
  content: "\e951";
}
.nav-icon-hiperlink-outline:before {
  content: "\e952";
}
.nav-icon-hiperlink:before {
  content: "\e953";
}
.nav-icon-heart-outline:before {
  content: "\e954";
}
.nav-icon-heart:before {
  content: "\e955";
}
.nav-icon-health-insurance-outline:before {
  content: "\e956";
}
.nav-icon-health-insurance:before {
  content: "\e957";
}
.nav-icon-glasses-bold:before {
  content: "\e958";
}
.nav-icon-glasses:before {
  content: "\e959";
}
.nav-icon-fold-outline:before {
  content: "\e95a";
}
.nav-icon-fold:before {
  content: "\e95b";
}
.nav-icon-female-outline:before {
  content: "\e95c";
}
.nav-icon-female:before {
  content: "\e95d";
}
.nav-icon-edit-outline:before {
  content: "\e95e";
}
.nav-icon-edit:before {
  content: "\e95f";
}
.nav-icon-drop-outline:before {
  content: "\e960";
}
.nav-icon-drop:before {
  content: "\e961";
}
.nav-icon-doctor-outline:before {
  content: "\e962";
}
.nav-icon-doctor:before {
  content: "\e963";
}
.nav-icon-dependentes-outline:before {
  content: "\e964";
}
.nav-icon-dependentes:before {
  content: "\e965";
}
.nav-icon-delete-outline:before {
  content: "\e966";
}
.nav-icon-delete:before {
  content: "\e967";
}
.nav-icon-close-outline:before {
  content: "\e968";
}
.nav-icon-close:before {
  content: "\e969";
}
.nav-icon-clock:before {
  content: "\e96a";
}
.nav-icon-clip:before {
  content: "\e96b";
}
.nav-icon-check-on:before {
  content: "\e96c";
}
.nav-icon-car-outline:before {
  content: "\e96d";
}
.nav-icon-car:before {
  content: "\e96e";
}
.nav-icon-camera-outline:before {
  content: "\e96f";
}
.nav-icon-camera:before {
  content: "\e970";
}
.nav-icon-calendar-outline:before {
  content: "\e971";
}
.nav-icon-calendar:before {
  content: "\e972";
}
.nav-icon-browser-url-outline:before {
  content: "\e973";
}
.nav-icon-browser-url:before {
  content: "\e974";
}
.nav-icon-birthday-outline:before {
  content: "\e975";
}
.nav-icon-birthday:before {
  content: "\e976";
}
.nav-icon-back-outline:before {
  content: "\e977";
}
.nav-icon-back:before {
  content: "\e978";
}
.nav-icon-avatar-outline:before {
  content: "\e979";
}
.nav-icon-avatar-2:before {
  content: "\e97a";
}
.nav-icon-avatar:before {
  content: "\e97b";
}
.nav-icon-arrow-up-outline:before {
  content: "\e97c";
}
.nav-icon-arrow-up:before {
  content: "\e97d";
}
.nav-icon-arrow-right-outline:before {
  content: "\e97e";
}
.nav-icon-arrow-right:before {
  content: "\e97f";
}
.nav-icon-arrow-left-outline:before {
  content: "\e980";
}
.nav-icon-arrow-left:before {
  content: "\e981";
}
.nav-icon-arrow-down-outline:before {
  content: "\e982";
}
.nav-icon-arrow-down:before {
  content: "\e983";
}
.nav-icon-arrow-corner-left-outline:before {
  content: "\e984";
}
.nav-icon-arrow-corner-left:before {
  content: "\e985";
}
.nav-icon-add:before {
  content: "\e986";
}
