html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.grecaptcha-badge {
  bottom: 94px!important;
}

.MuiDialog-container {
  width: 100%;
}

.MuiDialog-paperWidthSm {
  width: 100%;
}