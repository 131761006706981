@font-face {
  font-family: 'Dasa Sans';
  src: url('fonts/DasaSans-Bold.eot');
  src: url('fonts/DasaSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/DasaSans-Bold.woff') format('woff'),
    url('fonts/DasaSans-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Dasa Sans';
  src: url('fonts/DasaSans-Semibold.eot');
  src: url('fonts/DasaSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('fonts/DasaSans-Semibold.woff') format('woff'),
    url('fonts/DasaSans-Semibold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Dasa Sans';
  src: url('fonts/DasaSans-Medium.eot');
  src: url('fonts/DasaSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/DasaSans-Medium.woff') format('woff'),
    url('fonts/DasaSans-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Dasa Sans';
  src: url('fonts/DasaSans-Regular.eot');
  src: url('fonts/DasaSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/DasaSans-Regular.woff') format('woff'),
    url('fonts/DasaSans-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Dasa Sans';
  src: url('fonts/DasaSans-Light.eot');
  src: url('fonts/DasaSans-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/DasaSans-Light.woff') format('woff'),
    url('fonts/DasaSans-Light.woff2') format('woff2');
  font-weight: 300;
}