@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?h9yumr');
  src:  url('fonts/icomoon.eot?h9yumr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?h9yumr') format('truetype'),
    url('fonts/icomoon.woff?h9yumr') format('woff'),
    url('fonts/icomoon.svg?h9yumr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-id-card:before {
  content: "\e914";
}
.icon-check-list:before {
  content: "\e913";
  color: #000;
}
.icon-livia-logo:before {
  content: "\e912";
}
.icon-chevron_left:before {
  content: "\e910";
}
.icon-chevron_right:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e90d";
}
.icon-error:before {
  content: "\e90f";
}
.icon-call_end:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-mic_off:before {
  content: "\e909";
}
.icon-mic:before {
  content: "\e90a";
}
.icon-videocam_off:before {
  content: "\e90b";
}
.icon-videocam:before {
  content: "\e90c";
}
.icon-medical-report:before {
  content: "\e901";
}
.icon-house:before {
  content: "\e902";
}
.icon-help:before {
  content: "\e903";
}
.icon-expand-menu:before {
  content: "\e905";
}
.icon-drop-down:before {
  content: "\e906";
}
.icon-input:before {
  content: "\e90e";
  color: #c9cbcf;
}
.icon-drag:before {
  content: "\e904";
}
.icon-minus:before {
  content: "\e916";
}
.icon-telemedicine:before {
  content: "\e900";
}
.icon-pencil:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e986";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
